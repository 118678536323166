import CryptoJS from "crypto-js";

export default function encrypt(password, username) {
  // console.log(password, username);
  const text = username;

  const Keys = CryptoJS.SHA256(text);
  const Ivs = CryptoJS.SHA256("net.q_edu.fastimagine");
  // 用户名MD5的小写，前16位当秘钥
  const base64Str = CryptoJS.enc.Hex.stringify(Keys);
  const base64StrIv = CryptoJS.enc.Hex.stringify(Ivs);
  const key = CryptoJS.enc.Hex.parse(base64Str);
  const iv = CryptoJS.enc.Hex.parse(base64StrIv.substring(0, 32));

  // Encrypt
  const ciphertext = CryptoJS.AES.encrypt(password, key, {
    iv,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding,
  });
  const wordArray = CryptoJS.enc.Base64.parse(ciphertext.toString());

  return CryptoJS.enc.Hex.stringify(wordArray).toString();
}
